import { Controller } from "@hotwired/stimulus";
import focusLock from "dom-focus-lock";

// How many milliseconds to wait before we begin any timer-based actions
const INITIAL_DREAM_STATE_DELAY = 0;
// How many milliseconds of inactivity before we fade out the dream states (after the initial, one-time delay)
const DREAM_STATE_DELAY = 2000;
// How long the fade animation should take
const DREAM_STATE_SLOW_FADE_DURATION = 600;
const DREAM_STATE_FAST_FADE_DURATION = 400;

const INTERFACE_HIDE_DELAY = 2000;

export default class Lightbox extends Controller {
  static targets = [
    "dreamState",
    "header",
    "history",
    "historyEntry",
    "letterbox",
    "lightboxInfo",
    "nextLink",
    "prevLink",
  ];
  static values = {
    open: Boolean,
    history: Array,
    dreamStatesVisible: Boolean,
    interfaceVisible: Boolean,
    slowAnimation: Boolean,
    initialDelayElapsed: Boolean,
  };
  static focusReturnElement = null;

  connect() {
    // Only set if the lightbox is being opened for the first time
    Lightbox.focusReturnElement ||= document.activeElement;

    document.documentElement.style.setProperty("overflow", "hidden");
    document.body.style.setProperty("overflow", "hidden");

    this.initialDelayElapsedValue = false;
    this.initialDreamStateDelay = setTimeout(() => {
      this.initialDelayElapsedValue = true;
      this.hideDreamStates();
    }, INITIAL_DREAM_STATE_DELAY);

    focusLock.on(this.element);

    this.letterboxTarget.addEventListener("click", (e) => {
      if (e.target === this.letterboxTarget) {
        this.close();
      }
    });

    this.hideInterfaceAfterDelay();
  }

  disconnect() {
    focusLock.off(this.element);
    document.documentElement.style.removeProperty("overflow");
    document.body.style.removeProperty("overflow");
  }

  close() {
    this.element.remove();
    Lightbox.focusReturnElement.focus();
    Lightbox.focusReturnElement = null;
  }

  previous() {
    this.prevLinkTarget.click();
  }

  next() {
    this.nextLinkTarget.click();
  }

  showHideScrollIndicator() {
    if (this.historyTarget.scrollLeft === 0) {
      this.historyTarget.classList.add("lightbox__thumbnails--at-start");
      this.historyTarget.classList.remove("lightbox__thumbnails--at-end");
    } else if (
      this.historyTarget.scrollLeft === this.historyTarget.scrollWidth
    ) {
      this.historyTarget.classList.add("lightbox__thumbnails--at-end");
      this.historyTarget.classList.remove("lightbox__thumbnails--at-start");
    } else {
      this.historyTarget.classList.remove("lightbox__thumbnails--at-start");
      this.historyTarget.classList.remove("lightbox__thumbnails--at-end");
    }
  }

  // Dream States

  // Called on mousemove
  hideDreamStatesAfterDelay() {
    clearTimeout(this.dreamStateDelay);

    this.dreamStateDelay = setTimeout(() => {
      this.slowAnimationValue = true;
      this.hideDreamStates();
    }, DREAM_STATE_DELAY);
  }

  hideDreamStates() {
    clearTimeout(this.dreamStateDelay);
    this.dreamStatesVisibleValue = false;
  }

  showDreamStates() {
    clearTimeout(this.dreamStateDelay);
    this.toggleInitialHide();
    this.dreamStatesVisibleValue = true;
    this.hideDreamStatesAfterDelay();
  }

  toggleDreamStates() {
    clearTimeout(this.initialDreamStateDelay);
    this.initialDelayElapsedValue = true;
    this.slowAnimationValue = false;
    if (this.dreamStatesVisibleValue) {
      this.hideDreamStates();
    } else {
      this.showDreamStates();
    }
  }

  toggleInitialHide() {
    this.dreamStateTargets.forEach((dreamState) => {
      if (dreamState.classList.contains("initial-hide")) {
        dreamState.classList.remove("initial-hide");
      };
    });
  }

  dreamStatesVisibleValueChanged(visible) {
    if (!this.hasDreamStateTarget) {
      return;
    }

    // Cancel all in-progress animations
    // this.dreamStateTargets.forEach((dreamState) => {
    //   dreamState.getAnimations().forEach((animation) => {
    //     animation.cancel();
    //   });
    // });

    if (visible) {
      this.dreamStateTargets.forEach((dreamState) => {
        dreamState.style.setProperty("display", "block");
      });
      this.dreamStateTarget.offsetWidth; // Force a reflow
      this.dreamStateTargets.forEach((dreamState) => {
        dreamState.animate([{ opacity: 1 }], {
          duration: DREAM_STATE_SLOW_FADE_DURATION,
          fill: "forwards",
        });
      });
    } else {
      this.dreamStateTargets.forEach((dreamState) => {
        dreamState.animate([{ opacity: 0 }], {
          duration: DREAM_STATE_SLOW_FADE_DURATION,
          fill: "forwards",
        });
      });
    }
  }

  // Interface

  hideInterface() {
    this.headerTarget.classList.add("lightbox__header--hidden");
    this.lightboxInfoTarget.classList.add("lightbox__info--hidden");
  }

  showInterface() {
    clearTimeout(this.interfaceHideDelay);
    this.headerTarget.classList.remove("lightbox__header--hidden");
    this.lightboxInfoTarget.classList.remove("lightbox__info--hidden");
    this.hideInterfaceAfterDelay();
  }

  // Called on mousemove
  hideInterfaceAfterDelay() {
    clearTimeout(this.interfaceHideDelay);

    this.interfaceHideDelay = setTimeout(() => {
      this.interfaceVisibleValue = false;
      this.hideInterface();
    }, INTERFACE_HIDE_DELAY);
  }

  // Handlers

  handleClick(e) {
    if (e.target.closest(".js-lightbox__dream-state")) {
      return;
    }

    this.toggleDreamStates();
  }

  handleTouchStart(event) {
    // event.preventDefault();
    event.stopPropagation();
    const firstTouch = event.touches[0];
    this.touchPosition = {
      x: firstTouch.clientX,
      y: firstTouch.clientY,
    };

    this.showInterface();
  }

  handleTouchMove(event) {
    event.preventDefault();
    event.stopPropagation();

    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    const xDelta = this.touchPosition.x - x;
    const yDelta = this.touchPosition.y - y;
    const xDelta_abs = Math.abs(xDelta);
    const yDelta_abs = Math.abs(yDelta);
    const minimumYDeltaToClose = window.innerHeight * 0.425;

    // Make the lightbox follow the user's finger
    this.element.style.setProperty("transform", `translateY(${-1 * yDelta}px)`);

    // If the user swiped more horizontally than vertically,
    // Otherwise, close the lightbox by sliding it offscreen downwards.
    if (
      xDelta_abs > yDelta_abs ||
      yDelta_abs < minimumYDeltaToClose
    ) {
      return;
    }

    const translation = yDelta > 0 ? "-100vh" : "100vh";

    this.shouldCloseAfterSwipe = true;
    this.element
      .animate([{ transform: `translateY(${translation})` }], {
        duration: 100,
        fill: "forwards",
      })
      .finished.then(() => {
        this.close();
      });
  }

  handleTouchEnd() {
    if (this.shouldCloseAfterSwipe) {
      return;
    }

    this.touchPosition = {};
    this.element
      .animate([{ transform: "translateY(0)" }], {
        duration: 100,
      })
      .finished.then(() => {
        this.element.style.removeProperty("transform");
      });
  }
}
